import React from "react";
import { Box } from "@mui/material";
import SKILLS_IMAGES from "../images/skills-images";
import "../styles/SkillsCarousel.css";

const SkillsCarousel = () => {
  return (
    <Box>
      <p className="code-tags">&lt;div&gt;</p>

      <div className="skills-carousel-container">
        {SKILLS_IMAGES.map((skill, index) => {
          return (
            <figure key={index}>
              <img
                src={skill.image}
                alt={skill.title}
                className="skills-carousel-img"
              />
            </figure>
          );
        })}
      </div>
      <p className="code-tags">&lt;/div&gt;</p>
    </Box>
  );
};

export default SkillsCarousel;
