import betaPortal from "../images/beta-portal.png";
import sportsHub from "../images/sports-hub.png";
import ems from "../images/ems.png";
import dataVisualization from "../images/data-visualization.png";
import javaJamCafe from "../images/java-jam-cafe.png";
import driveTestCenter from "../images/drive-test-center.png";

const PROJECTS_DATA = [
  {
    image: betaPortal,
    company: "betaPortal",
    description:
      "A project and resource management portal. Built using ReactJS, NodeJS, GraphQL, and MongoDB.",
    link: "https://betaportal.netlify.app/",
    note: "** Test credentials available upon request **",
  },
  {
    image: sportsHub,
    company: "Sports Hub",
    description:
      "A tribute website for great sportsmen. Built using plain HTML, CSS, and JavaScript with a live streaming feature. ",
    link: "https://sports-hub-saim-alam.netlify.app",
  },
  {
    image: ems,
    company: "EMS",
    description:
      "An employee management system. Built using ReactJS, NodeJS, GraphQL, and MongoDB.",
    link: "https://ems-saimalam.netlify.app",
  },
  {
    image: dataVisualization,
    company: "Data Visualization",
    description:
      "A college group project on data visualization. Built using D3.js, HTML, CSS, and JavaScript.",
    link: "https://saimalam93.github.io/COVID-D3JS/",
  },
  {
    image: javaJamCafe,
    company: "Java Jam Cafe",
    description: "A coffee shop website. Built using WordPress and PHP.",
    link: "http://saimalam.ezyro.com/",
  },
  {
    image: driveTestCenter,
    company: "Drive Test Center",
    description:
      "A driving test center website, featuring three user personas of the admin, examiner and the applicant. Built using NodeJS, MongoDB, and ExpressJS.",
    link: "https://saim-alam-dtc.onrender.com",
  },
];

export default PROJECTS_DATA;
