import React from "react";
import { Box } from "@mui/material";
import "../styles/MessageSent.css";

const MessageSent = () => {
  return (
    <Box className="bouncing-text">
      <div className="m">M</div>
      <div className="e1">E</div>
      <div className="s1">S</div>
      <div className="s2">S</div>
      <div className="a">A</div>
      <div className="g">G</div>
      <div className="e2">E</div>
      <span>&nbsp;</span>
      <div className="s3">S</div>
      <div className="e3">E</div>
      <div className="n">N</div>
      <div className="t">T</div>
      <span>&nbsp;</span>
      <div className="sym">!</div>
      <div className="shadow"></div>
      <div className="shadow-two"></div>
    </Box>
  );
};

export default MessageSent;
