import React from "react";
import { Box, Typography } from "@mui/material";
import Timeline from "../components/Timeline";
import "../styles/About.css";

const About = () => {
  return (
    <Box id="about" className="about">
      <p className="code-tags">&lt;div&gt;</p>
      <div className="about-container">
        <div className="headers">
          <p className="code-tags">&lt;h1&gt;</p>
          <Typography variant="h3" component="h2" className="headers">
            About Me
          </Typography>
          <p className="code-tags">&lt;/h1&gt;</p>
        </div>
        <div className="about-content">{/* Something about me here */}</div>
        <div className="about-timeline">
          <Timeline />
        </div>
      </div>

      <p className="code-tags">&lt;/div&gt;</p>
    </Box>
  );
};

export default About;
