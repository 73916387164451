import React from "react";
import { Box, Typography } from "@mui/material";

const ProjectCard = (props) => {
  return (
    <Box className="magic-card">
      <img src={props.image} alt={props.company} className="magic-card-img" />
      <div className="magic-card-content">
        <Typography variant="h5" component="h2" className="magic-card-company">
          {props.company}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className="magic-card-description"
        >
          {props.description}
        </Typography>
        {props.note ? (
          <Typography variant="body1" component="p" className="note">
            {props.note}
          </Typography>
        ) : null}
      </div>
    </Box>
  );
};

export default ProjectCard;
