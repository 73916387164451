import React from "react";
import Box from "@mui/material/Box";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";

const SocialLinks = (props) => {
  return (
    <Box className="social-links">
      <a
        href="https://www.linkedin.com/in/saimalam/"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.setOpen(false)}
      >
        <LinkedInIcon className="social-icon" sx={{ color: "#1a1a1a" }} />
      </a>
      <a
        href="https://www.github.com/saimalam93"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.setOpen(false)}
      >
        <GitHubIcon className="social-icon" sx={{ color: "#1a1a1a" }} />
      </a>
      <a
        href="https://www.facebook.com/saimalam"
        target="_blank"
        rel="noreferrer"
        onClick={() => props.setOpen(false)}
      >
        <FacebookIcon className="social-icon" sx={{ color: "#1a1a1a" }} />
      </a>
    </Box>
  );
};

export default SocialLinks;
