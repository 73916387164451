const sendMessage = async (message) => {
  const url = "https://portfolio-backend-saim.onrender.com/send";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(message),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.json();
};
export default sendMessage;
