import React, { useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";
import HorizontalTimeline from "react-horizontal-timeline";
import { DESCRIPTION, VALUES } from "../config/timeline-config";
import TimelineCard from "./TimelineCard";

const Timeline = () => {
  const [value, setValue] = useState(0);
  const [previous, setPrevious] = useState(0);

  const handleContentChange = (index) => {
    let temp = [...DESCRIPTION];
    temp.forEach((item) => {
      item.show = false;
    });
    temp[index].show = true;
  };

  return (
    <Box>
      <p className="code-tags">&lt;div&gt;</p>
      <div className="timeline-container">
        <HorizontalTimeline
          values={VALUES}
          index={value}
          getLabel={(date) => date.slice(0, 4)}
          minEventPadding={10}
          maxEventPadding={10}
          linePadding={50}
          styles={{
            background: "#de0000",
            foreground: "#ea4c46",
            outline: "#fff",
          }}
          indexClick={(index) => {
            setValue(index);
            setPrevious(value);
            handleContentChange(index, value);
          }}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "60%",
          margin: "0 auto",
        }}
      >
        <ArrowBackIosNewIcon
          sx={{
            color: "#ea4c46",
            fontSize: "30px",
            cursor: "pointer",
            border: "2px solid #1a1a1a",
            borderRadius: "5%",
            padding: "5px",
            backdropFilter: "blur(5px)",
            boxShadow: "5px 10px 10px 2px rgba(1, 1, 1, 0.7);",
          }}
          onClick={() => {
            if (value > 0) {
              setValue(value - 1);
              setPrevious(value);
              handleContentChange(value - 1, value);
            }
          }}
        />
        {DESCRIPTION.map((item, index) => {
          return (
            <TimelineCard
              key={index}
              item={item}
              index={index}
              previous={previous}
            />
          );
        })}
        <ArrowForwardIosIcon
          sx={{
            color: "#ea4c46",
            fontSize: "30px",
            cursor: "pointer",
            border: "2px solid #1a1a1a",
            borderRadius: "5%",
            padding: "5px",
            backdropFilter: "blur(5px)",
            boxShadow: "2px 5px 5px 1px rgba(1, 1, 1, 0.7);",
          }}
          onClick={() => {
            if (value < VALUES.length - 1) {
              setValue(value + 1);
              setPrevious(value);
              handleContentChange(value + 1, value);
            }
          }}
        />
      </Box>
      <p className="code-tags">&lt;/div&gt;</p>
    </Box>
  );
};

export default Timeline;
