import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import EmailIcon from "@mui/icons-material/Email";
import ProfilePic from "../DPSquare.jpg";

const ProfileCard = () => {
  return (
    <Card className="ProfilePic-card">
      <CardHeader
        subheader="Full Stack Developer"
        className="ProfilePic-header"
        subheaderTypographyProps={{
          color: "#fff",
          fontSize: "11px",
          fontWeight: "bold",
          letterSpacing: "1px",
        }}
      />
      <CardMedia
        height="130"
        component="img"
        alt="ProfilePic"
        image={ProfilePic}
        className="ProfilePic"
        sx={{ objectFit: "contain" }}
      />
      <CardContent className="email">
        <div className="email-text">
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=saimalam.work@gmail.com"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#fff",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmailIcon sx={{ fontSize: "15px", marginRight: "5px" }} />
            <Typography
              variant="caption"
              sx={{
                color: "#fff",
                fontSize: "11px",
                fontWeight: "bold",
                letterSpacing: "2px",
              }}
            >
              Email Me!
            </Typography>
          </a>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
