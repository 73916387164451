import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { createTheme, useMediaQuery } from "@mui/material";
import { Drawer } from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import Sidenav from "./Sidenav";
import $ from "jquery";

const Navigation = () => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const theme = createTheme({
    breakpoints: {
      values: {
        tablet: 901,
        laptop: 1024,
        desktop: 1400,
      },
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const isTablet = useMediaQuery(theme.breakpoints.between("tablet", "laptop"));
  const navMaxWidth = isMobile ? "100vw" : isTablet ? "50%" : "10%";

  const variant = isMobile || isTablet ? "temporary" : "permanent";

  $(window).on("resize", () => {
    setHeight(window.innerHeight);
  });

  useEffect(() => {}, [height]);

  return (
    <>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor="left"
        variant={variant}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: open
              ? "#ffffffdb !important"
              : "transparent !important",
            color: "#1a1a1a",
            width: "100%",
            maxWidth: navMaxWidth,
            border: "none",
            boxShadow: "none",
            backdropFilter: open ? "blur(5px)" : "blur(15px)",
            webkitBackdropFilter: "blur(15px)",
            transition: "all 0.5s ease",
            height: height,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          },
        }}
      >
        {isMobile || isTablet ? (
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              marginLeft: "auto",
            }}
          >
            <CloseIcon
              sx={{
                color: "#1a1a1a",
              }}
            />
          </IconButton>
        ) : null}
        <Sidenav setOpen={setOpen} variant={variant} />
      </Drawer>
      {isMobile || isTablet ? (
        <IconButton
          sx={{
            color: "#1a1a1a",
            marginLeft: "auto",
            position: "sticky",
            top: "0",
            zIndex: "10",
            visibility: open ? "hidden" : "visible",
          }}
          onClick={() => setOpen(!open)}
        >
          <MenuIcon />
        </IconButton>
      ) : null}
    </>
  );
};

export default Navigation;
