import React from "react";
import PersonPinIcon from "@mui/icons-material/PersonPin";

const LocationPin = () => {
  return (
    <div className="pin">
      <PersonPinIcon className="pin-icon" />
      <p className="pin-text">My Location</p>
    </div>
  );
};

export default LocationPin;
