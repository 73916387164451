import React from "react";
import GoogleMapReact from "google-map-react";
import MAP_OPTIONS from "../config/map-config";
import LocationPin from "./LocationPin";

const ContactMap = () => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const location = {
    lat: 44.0384,
    lng: -79.2,
    city: `Greater Toronto Area`,
    province: `Ontario`,
    country: `Canada`,
  };

  return (
    <div className="map">
      <div className="address">
        {location.city}
        <br />
        {location.province} - {location.country}
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={location}
        defaultZoom={8}
        options={MAP_OPTIONS}
      >
        <LocationPin lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  );
};

export default ContactMap;
