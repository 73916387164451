import $ from "jquery";

const bounce = () => {
  $(() => {
    document.querySelectorAll(".bouncing-letters>span").forEach((element) => {
      element.addEventListener("mouseover", (e) => bounce(e.target));
    });

    function bounce(letter) {
      if (!letter.classList.contains("bounce")) {
        letter.classList.add("fill-color");
        letter.classList.add("bounce");
        setTimeout(function () {
          letter.classList.remove("bounce");
          letter.classList.remove("fill-color");
        }, 1000);
      }
    }
  });
};

export default bounce;
