import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import "../styles/Home.css";
import "../styles/bounce-anim.css";
import SkillsCarousel from "../components/SkillsCarousel";

import bounce from "../utilities/bounce";
import $ from "jquery";
// import ParticleBackground from "../components/ParticleBackground";

const Home = () => {
  useEffect(() => {
    bounce();
  }, []);

  return (
    <>
      <Box id="home" className="home">
        <p className="code-tags">&lt;div&gt;</p>
        <div className="home-container">
          <h1 className="bouncing-letters">
            <p className="code-tags">&lt;span&gt;</p>
            <span>H</span>
            <span>i</span>
            <br />
            <span>I</span>
            <span>'</span>
            <span>m</span>
            <span>&nbsp;</span>
            <span className="logo-span">S</span>
            <span className="logo-span">a</span>
            <span className="logo-span">i</span>
            <span className="logo-span">m</span>
            <br />
            <span>a</span>
            <span>&nbsp;</span>
            <span>w</span>
            <span>e</span>
            <span>b</span>
            <span>&nbsp;</span>
            <span>d</span>
            <span>e</span>
            <span>v</span>
            <span>e</span>
            <span>l</span>
            <span>o</span>
            <span>p</span>
            <span>e</span>
            <span>r</span>
            <span>.</span>
            <p className="code-tags">&lt;/span&gt;</p>
          </h1>
          <div className="home-content">
            <SkillsCarousel />
          </div>
          <div className="contact-btn-container">
            <p className="code-tags">&lt;button&gt;</p>
            <button
              className="btn-contact"
              onClick={() => {
                $("#contact")[0].scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              Reach Out !
            </button>
            <p className="code-tags">&lt;/button&gt;</p>
          </div>
        </div>

        <p className="code-tags">&lt;/div&gt;</p>
      </Box>
      {/* <ParticleBackground /> */}
    </>
  );
};

export default Home;
