import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "./Logo";
import NavLinks from "./NavLinks";
import ProfileCard from "./ProfileCard";
import SocialLinks from "./SocialLinks";
import "../styles/sidenav.css";

const Sidenav = (props) => {
  const date = new Date();
  return (
    <Box className="sidenav">
      <Logo />
      <ProfileCard />
      <NavLinks setOpen={props.setOpen} variant={props.variant} />
      <SocialLinks setOpen={props.setOpen} />
      <Typography variant="caption" sx={{ color: "#1a1a1a" }}>
        &copy; Saim Alam {date.getFullYear()}
      </Typography>
    </Box>
  );
};

export default Sidenav;
