import React from "react";
import Box from "@mui/material/Box";
import "../styles/Projects.css";
import { Typography, Link } from "@mui/material";
import ProjectCard from "../components/ProjectCard";
import PROJECTS_DATA from "../config/project-config";

const Projects = () => {
  return (
    <Box id="projects" className="projects">
      <p className="code-tags">&lt;div&gt;</p>
      <div className="projects-container">
        <div className="headers">
          <p className="code-tags">&lt;h1&gt;</p>
          <Typography variant="h3" component="h2" className="headers">
            My Projects
          </Typography>
          <p className="code-tags">&lt;/h1&gt;</p>
        </div>
        <div className="projects-content">
          {PROJECTS_DATA.map((project) => (
            <Link
              key={project.company}
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              <ProjectCard
                key={project.company}
                image={project.image}
                company={project.company}
                description={project.description}
                link={project.link}
                note={project.note}
              />
            </Link>
          ))}
        </div>
      </div>
      <p className="code-tags">&lt;/div&gt;</p>
    </Box>
  );
};

export default Projects;
