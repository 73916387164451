import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { Box, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailValidator from "../utilities/emailValidator";
import sendMessage from "../utilities/sendMessage";
import MessageSent from "./MessageSent";

const ContactForm = () => {
  const FORM_STYLE = {
    input: {
      color: "#000",
    },
    label: {
      color: "#000",
    },
    root: {
      color: "#000",
    },
    inputProps: {
      color: "#000",
      minHeight: "100px",
    },
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [sending, setSending] = useState(false);

  useEffect(() => {}, [name, email, message, sending]);

  const toastErrors = (errors) => {
    errors.forEach((error) => {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        transition: Bounce,
        type: "error",
      });
    });
  };

  const cleanInputs = (obj) => {
    obj.name = obj.name.replace(/[&/\\#+()$~%'":*?<>{}]/g, "").trim();
    obj.email = obj.email.replace(/[&/\\#+()$~%'":*?<>{}]/g, "").trim();
    obj.message = obj.message.replace(/[&/\\#+()$~%'":*?<>{}]/g, "").trim();
    return obj;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = [];
    let obj = {
      name: name,
      email: email,
      message: message,
    };

    obj = await cleanInputs(obj);

    if (obj.name === "") {
      errors.push({ message: "Name is required" });
    }
    if (obj.email === "") {
      errors.push({ message: "Email is required" });
    } else if (!emailValidator(obj.email)) {
      errors.push({ message: "Email is invalid" });
    }
    if (obj.message === "") {
      errors.push({ message: "Message is required" });
    }

    if (errors.length > 0) {
      toastErrors(errors);
    } else {
      setSending(true);
      const response = await sendMessage(obj);
      if (response.status === "success") {
        setSending(false);
        setStatus(true);
        setTimeout(() => {
          setStatus(false);
        }, 3000);
      } else if (response.status === "fail") {
        toast.error("Message failed to send. Please try again.", {
          position: "center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          transition: Bounce,
          type: "error",
        });
      }
    }
  };

  return (
    <Box>
      <>
        <ToastContainer />
      </>
      <p className="code-tags">&lt;form&gt;</p>
      <form className="form-container" onSubmit={handleSubmit} method="POST">
        {status ? (
          <MessageSent />
        ) : (
          <>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              className="form-input"
              sx={FORM_STYLE}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              className="form-input"
              sx={FORM_STYLE}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              multiline
              label="Message"
              variant="outlined"
              className="form-input"
              sx={FORM_STYLE}
              inputProps={{ style: FORM_STYLE.inputProps }}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="contact-btn-container">
              <p className="code-tags">&lt;button&gt;</p>
              <LoadingButton
                variant="outlined"
                className="form-button"
                startIcon={<SendIcon />}
                loading={sending}
                loadingPosition="end"
                type="submit"
              >
                Send
              </LoadingButton>
              <p className="code-tags">&lt;/button&gt;</p>
            </div>
          </>
        )}
      </form>
      <p className="code-tags">&lt;/form&gt;</p>
    </Box>
  );
};

export default ContactForm;
