import React from "react";
import Box from "@mui/material/Box";
import "../styles/Contact.css";
import { Typography } from "@mui/material";
import ContactForm from "../components/ContactForm";
import ContactMap from "../components/ContactMap";

const Contact = () => {
  return (
    <Box id="contact" className="contact">
      <Box className="contact-form">
        <p className="code-tags">&lt;div&gt;</p>
        <div className="contact-container">
          <div className="headers">
            <p className="code-tags">&lt;h1&gt;</p>
            <Typography variant="h3" component="h2" className="headers">
              Contact Me
            </Typography>
            <p className="code-tags">&lt;/h1&gt;</p>
          </div>
          <div className="contact-content">
            <ContactForm />
          </div>
        </div>

        <p className="code-tags">&lt;/div&gt;</p>
      </Box>
      <div className="contact-map">
        <ContactMap />
      </div>
    </Box>
  );
};

export default Contact;
