import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";

const TimelineCard = (props) => {
  return (
    <Slide
      direction={props.index > props.previous ? "left" : "right"}
      timeout={{
        enter: 1000,
        exit: 0,
      }}
      in={props.item.show}
      mountOnEnter
      unmountOnExit
    >
      <div className="timeline-content-container">
        <Card
          sx={{
            backgroundColor: "transparent",
            backdropFilter: "blur(10px)",
            color: "#1a1a1a",
            boxShadow: "10px 5px 10px 10px rgba(1,1,1,0.7)",
          }}
          className="timeline-card"
        >
          <CardMedia
            component="img"
            height="250"
            image={props.item.image}
            alt={props.item.alt}
            sx={{ objectFit: "contain" }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="timeline-card-header"
            >
              {props.item.heading}
            </Typography>
            <Typography
              variant="body2"
              color="#000"
              className="timeline-card-description"
            >
              {props.item.description}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Slide>
  );
};

export default TimelineCard;
