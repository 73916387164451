import helloworld from "../images/hello-world.png";
import backgroundCode from "../images/background-code.png";
import plan from "../images/plan.jpg";
import freelancer from "../images/freelancer.jpg";
import freelance from "../images/freelance.jpg";
import graduation from "../images/graduation.jpg";
import webDesign from "../images/web-design.jpg";
import dreamJob from "../images/dream-job.jpg";

export const VALUES = [
  "2005-01-01",
  "2009-01-01",
  "2011-01-01",
  "2013-01-01",
  "2015-01-01",
  "2019-01-01",
  "2021-01-01",
  "2023-01-01",
];

export const DESCRIPTION = [
  {
    heading: "First Code",
    description: "I wrote my first ever code in C++",
    show: true,
    image: helloworld,
    alt: "Hello World",
  },
  {
    heading: "Programming",
    description: "I became obsessed with computers and programming.",
    show: false,
    image: backgroundCode,
    alt: "Code",
  },
  {
    heading: "Career Choice",
    description: "I decided to pursue a career in Computer Science.",
    show: false,
    image: plan,
    alt: "Plan",
  },
  {
    heading: "Freelance",
    description: "I started working as a freelancer.",
    show: false,
    image: freelancer,
    alt: "Freelancer",
  },
  {
    heading: "Still Freelancing",
    description:
      "My freelance projects included not only web development but also brochure design, logo design, and other graphic design work.",
    show: false,
    image: freelance,
    alt: "Freelance",
  },
  {
    heading: "Graduation",
    description:
      "I graduated with a Bachelor's degree in Computer Engineering.",
    show: false,
    image: graduation,
    alt: "Graduation",
  },
  {
    heading: "Post Graduation",
    description:
      "I pursued post graduation geared towards Web Design and Development.",
    show: false,
    image: webDesign,
    alt: "Web Design",
  },
  {
    heading: "Future",
    description:
      "I am looking forward to working as a full time developer enchancing my skills and knowledge.",
    show: false,
    image: dreamJob,
    alt: "Dream Job",
  },
];
