import "./styles/App.css";
import Container from "@mui/material/Container";
import Home from "./pages/Home";
import React from "react";
import PaticleBackground from "./components/ParticleBackground";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Navigation from "./components/Navigation";

function App() {
  return (
    <>
      <PaticleBackground />
      <Navigation />
      <Container className="App" disableGutters={true} maxWidth={false}>
        <Home />
        <About />
        <Projects />
        <Contact />
      </Container>
    </>
  );
}

export default App;
