import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material/";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import $ from "jquery";
import Resume from "../Alam_Saim_Resume.pdf";

const NavLinks = (props) => {
  const defaultTab = props.variant === "temporary" ? false : "one";
  const [value, setValue] = useState(defaultTab);

  const handleChange = (event, newValue) => {
    props.setOpen(false);
    event.preventDefault();
    switch (newValue) {
      case "one":
        const element = $("#home")[0];
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "two":
        const element2 = $("#about")[0];
        element2.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "three":
        const element3 = $("#projects")[0];
        element3.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "four":
        const element4 = $("#contact")[0];
        element4.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      default:
        break;
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (props.variant === "temporary") {
      handleClicked(value);
    }
  }, [value, props]);

  const handleClicked = (val) => {
    switch (val) {
      case "one":
        const element = $("#home")[0];
        element.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "two":
        const element2 = $("#about")[0];
        element2.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "three":
        const element3 = $("#projects")[0];
        element3.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "four":
        const element4 = $("#contact")[0];
        element4.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      default:
        break;
    }
  };

  return (
    <Box className="nav-links">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
        orientation="vertical"
        textColor="inherit"
      >
        <Tab
          value="one"
          label="Home"
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <Tab
          value="two"
          label="About"
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <Tab
          value="three"
          label="Projects"
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <Tab
          value="four"
          label="Contact"
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <a
          href={Resume}
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setOpen(false)}
          style={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            margin: "5px auto 0px auto",
            width: "90%",
          }}
        >
          <Button
            variant="outlined"
            endIcon={<OpenInNewIcon />}
            sx={{
              width: defaultTab ? "80%" : "40%",
              color: "#000",
              border: "1px solid #ea4c46",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Resume
          </Button>
        </a>
      </Tabs>
    </Box>
  );
};

export default NavLinks;
