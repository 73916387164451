import ADOBEXD from "./skill-logos/adobexd.png";
import C from "./skill-logos/c++.png";
import CANVA from "./skill-logos/canva.png";
import CSS from "./skill-logos/css.png";
import DOCKER from "./skill-logos/docker.png";
import FIGMA from "./skill-logos/figma.png";
import GRAPHQL from "./skill-logos/graphql.png";
import HTML from "./skill-logos/html.png";
import JQUERY from "./skill-logos/jquery.png";
import JS from "./skill-logos/js.png";
import MONGODB from "./skill-logos/mongodb.png";
import MUI from "./skill-logos/mui.png";
import NODEJS from "./skill-logos/nodejs.png";
import PHP from "./skill-logos/php.png";
import PYTHON from "./skill-logos/python.png";
import REACT from "./skill-logos/react.png";
import SQL from "./skill-logos/sql.png";

const SKILLS_IMAGES = [
  {
    image: ADOBEXD,
    title: "Adobe XD",
    description: "I use Adobe XD to design my websites and apps.",
  },
  {
    image: C,
    title: "C++",
    description: "I use C++ to solve problems and to learn algorithms.",
  },
  {
    image: CANVA,
    title: "Canva",
    description: "I use Canva to design my logos and other graphics.",
  },
  {
    image: CSS,
    title: "CSS 3",
    description: "I use CSS 3 to style my websites and apps.",
  },
  {
    image: DOCKER,
    title: "Docker",
    description: "I use Docker to containerize my apps.",
  },
  {
    image: FIGMA,
    title: "Figma",
    description: "I use Figma to design my websites and apps.",
  },
  {
    image: GRAPHQL,
    title: "GraphQL",
    description: "I use GraphQL to query my databases.",
  },
  {
    image: HTML,
    title: "HTML 5",
    description: "I use HTML 5 to structure my websites and apps.",
  },
  {
    image: JQUERY,
    title: "jQuery",
    description: "I use jQuery to simplify my JavaScript code.",
  },
  {
    image: JS,
    title: "JavaScript",
    description: "I use JavaScript to make my websites and apps interactive.",
  },
  {
    image: MONGODB,
    title: "MongoDB",
    description: "I use MongoDB to store my data.",
  },
  {
    image: MUI,
    title: "Material UI",
    description: "I use Material UI to style my websites and apps.",
  },
  {
    image: NODEJS,
    title: "Node.js",
    description: "I use Node.js to run my JavaScript code.",
  },
  {
    image: PHP,
    title: "PHP",
    description: "I use PHP to make my websites and apps dynamic.",
  },
  {
    image: PYTHON,
    title: "Python",
    description: "I use Python to solve problems and to learn algorithms.",
  },
  {
    image: REACT,
    title: "React",
    description: "I use React to build my websites and apps.",
  },
  {
    image: SQL,
    title: "SQL",
    description: "I use SQL to query my databases.",
  },
];

export default SKILLS_IMAGES;
